import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Seo from '../components/seo';
import parseFlexibleContent from '../utils/parse-flexible-content';
import renderFlexibleContent from '../utils/render-flexible-content';
import { SINGLE } from '../utils/constants';
import PageHeader from '../components/molecules/page-header';
import Intro from '../components/molecules/intro';
import ContactBody from '../components/molecules/contact-body';
import FormRenderer from '../components/organisms/form-renderer';

const modules = {
  Intro,
  ContactBody,
  Form: FormRenderer,
};

const Contact = ({ data }) => {
  const flexibleContent = parseFlexibleContent(data.page.acf.flexible_content_page);

  const embeds = [
    {
      key: 'form',
      type: SINGLE,
    },
  ];

  const injectData = {
    ContactBody: {
      socialMediaLinks: data.siteOptions.options.socialMediaLinks,
      email: data.siteOptions.options.email,
    },
  };

  const ogImage = data.page.yoast.ogImage && data.page.yoast.ogImage.localFile;
  const twitterImage = data.page.yoast.twitterImage && data.page.yoast.twitterImage.localFile;

  return (
    <Layout>
      <Seo
        fallbackTitle={data.page.title}
        title={data.page.yoast.title}
        description={data.page.yoast.metadesc}
        ogImage={ogImage}
        twitterImage={twitterImage}
      />
      <PageHeader title={data.page.title} />
      {renderFlexibleContent(flexibleContent, modules, injectData, embeds)}
    </Layout>
  );
};

export const query = graphql`
  query($id: Int!) {
    page: wordpressPage(wordpress_id: { eq: $id }) {
      yoast {
        title
        metadesc
        ogImage: opengraph_image {
          localFile {
            childImageSharp {
              resize(width: 1200, height: 630) {
                src
              }
            }
          }
        }
        twitterImage: twitter_image {
          localFile {
            childImageSharp {
              resize(width: 1000, height: 1000) {
                src
              }
            }
          }
        }
      }
      title
      acf {
        flexible_content_page {
          module: __typename
          ... on WordPressAcf_intro {
            title
            body
          }
          ... on WordPressAcf_contact_body {
            image {
              localFile {
                childImageSharp {
                  sizes(maxWidth: 443) {
                    ...GatsbyImageSharpSizes
                  }
                }
              }
              alt_text
            }
            body
          }
          ... on WordPressAcf_form {
            form {
              wordpress_id
              acf {
                title
                background_image {
                  localFile {
                    childImageSharp {
                      sizes(maxWidth: 640) {
                        ...GatsbyImageSharpSizes
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    siteOptions: wordpressAcfOptions {
      options {
        socialMediaLinks {
          url
          network
          text
        }
        email
      }
    }
  }
`;

export default Contact;
