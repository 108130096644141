import React from 'react';
import classNames from 'classnames';
import getGraphqlImage from '../../utils/get-graphql-image';
import DefList from '../atoms/def-list';
import Img from 'gatsby-image';

const ContactBody = ({ className, image, body, emailTitle, email, socialMediaLinks }) => {
  const imageSizes = getGraphqlImage(image);
  const { alt_text: alt } = image;

  const items = [
    {
      title: emailTitle,
      url: `mailto:${email}`,
      text: email,
    },
    ...socialMediaLinks.map(({ network, url, text }) => ({
      title: network,
      url,
      text,
    })),
  ];

  return (
    <section className={classNames('contact-body', className)}>
      <div className="contact-body__inner container">
        <div className="contact-body__content-wrapper">
          <div className="contact-body__content">
            <Img className="contact-body__image contact-body__image--medium" alt={alt} fluid={imageSizes} />
            <div className="contact-body__body" dangerouslySetInnerHTML={{ __html: body }} />
            <DefList items={items} />
          </div>
          <div className="contact-body__aside">
            <Img className="contact-body__image contact-body__image--full" alt="" fluid={imageSizes} />
          </div>
        </div>
      </div>
    </section>
  );
};

ContactBody.defaultProps = {
  emailTitle: 'Email',
};

export default ContactBody;
