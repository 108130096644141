import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const PageHeader = ({ title, standalone }) => (
  <section
    className={classNames('page-header', {
      'page-header--standalone': standalone,
    })}
  >
    <div className="page-header__inner container">
      <h1 className="page-header__title" dangerouslySetInnerHTML={{ __html: title }} />
    </div>
  </section>
);

PageHeader.propTypes = {
  standalone: PropTypes.bool,
};

PageHeader.defaultProps = {
  standalone: false,
};

export default PageHeader;
